@import "../mixins/flex.scss";
@import "../mixins/fonts.scss";

.navWrap {
  position: relative;
  background: var(--bg-primary);
  .navContainer {
    padding: 0px 16px;
    .content {
      @include flexCenterBetween();
      min-height: 72px;
      .actions {
        .actionIcon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
      .list {
        display: none;
      }
      .menu {
        display: none;
      }
      .mobileOpen {
        display: block;
      }
      .mobileClose {
        display: none;
      }
      .mobileList {
        position: absolute;
        left: 0;
        top: 74px;
        background-color: #fff;
        border-top: 1px solid var(--border-dk);
        height: 100%;
        z-index: 5;
        width: 100%;
        height: 100vh;
        padding: 24px 16px;

        .mobileListContent {
          .mobileListItem {
            h3 {
              @include setFontPrimaryBold(16px, var(--clr-secondary-ltr));
            }
          }
        }
        .mobileListContent:not(:last-child) {
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 991px) {
  .navWrap {
    .navContainer {
      padding: 0px 32px;

      .content {
        .actions {
          display: none;
        }
        .list {
          display: flex;
          gap: 30px;
          .listItem {
            @include flexCenterNil();
            gap: 6px;
            cursor: pointer;

            span {
              @include setFontPrimaryBold(16px, var(--clr-secondary-ltr));
            }
            .icon {
              width: 18px;
              height: 18px;
            }
          }
        }
        .menu {
          position: absolute;
          z-index: 15;
          width: 100%;
          left: 0;
          top: 74px;
          background-color: #fff;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -2px rgba(0, 0, 0, 0.1);
          padding: 36px 30px;
          border-top: 1px solid var(--border-dk);
          border-bottom: 1px solid var(--border-dk);
          .menuContent {
            padding: 0px 32px;
            display: flex;
            gap: 30px;
            .menuContentItem {
              width: 33.3%;
              .menuHeader {
                @include setFontPrimaryBold(18px, var(--clr-secondary-ltr));
                padding-bottom: 4px;
              }
              .subList {
                padding-left: 10px;
                .subListItem {
                  span {
                    @include setFontPrimary(16px, var(--clr-secondary));
                    cursor: pointer;
                  }
                }
                .subListItem:not(:last-child) {
                  margin-bottom: 6px;
                }
              }
            }
          }
        }
        .open {
          display: block;
        }
        .close {
          display: none;
        }
      }
    }
  }
}
