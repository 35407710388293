@import "../mixins/fonts.scss";

.footerWrap {
  position: relative;
  bottom: 0px;
  background: var(--clr-secondary);
  clear: both;
  .footerContainer {
    padding: 32px 12px;
    .content {
      .logo {
        margin-bottom: 48px;

        .about {
          padding-top: 18px;
          p {
            @include setFontPrimary(16px, var(--text-white));
          }
        }
      }

      .list {
        .listItem {
          .listTitle {
            @include setFontPrimaryBold(14px, var(--text-white));
            text-transform: uppercase;
            padding-bottom: 12px;
          }
          .innerList {
            .innerListItem {
              span {
                @include setFontPrimary(16px, var(--text-white));
                cursor: pointer;
              }
            }
            .innerListItem:not(:last-child) {
              margin-bottom: 9px;
            }
          }
        }
        .listItem:not(:last-child) {
          margin-bottom: 38px;
        }
      }
    }

    .footer {
      padding-top: 38px;
      text-align: center;
      p {
        @include setFontPrimary(14px, var(--text-faded-dk));
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .footerWrap {
    .footerContainer {
      padding: 32px 20px;
      .content {
        display: flex;
        justify-content: space-between;
        .logo {
          width: 23%;
        }
        .list {
          width: 73%;
          display: flex;
          .listItem {
            width: 33.3%;
          }
          .listItem {
            margin-bottom: 0px;
          }
        }
      }
      .footer {
        padding-top: 18px;
      }
    }
  }
}

@media screen and (min-width: 991px) {
  .footerWrap {
    .footerContainer {
      padding: 48px;
    }
  }
}
