.logoWrap {
  display: block;
  cursor: pointer;
  width: 170px;
  .logo {
    position: relative;
    width: 100%;
    height: 27px !important;
    object-fit: contain;
  }
}
